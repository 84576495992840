import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider, CssBaseline, Drawer, List, ListItem, ListItemText, Typography, Box, Chip, Divider, AppBar, Toolbar, IconButton, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import theme from './theme';
import { Home } from './pages/home';
import { ProjectCreation } from './pages/home/projectCreation';
import { Home as Home3D } from './pages/home/3d';

const queryClient = new QueryClient();

const Sidebar = ({ isMobile, isOpen, onClose }) => {
  const navigate = useNavigate();

  const drawerContent = (
    <>
      <Box sx={{ 
        padding: theme.spacing(2), 
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center', 
      }}>
        <Typography 
          variant="h6" 
          fontFamily="'Roboto Slab', serif" 
          fontWeight="bold"
          sx={{ marginRight: theme.spacing(2) }}
        >
          Pave.ing
        </Typography>
        <Chip 
          label="Demo" 
          color="primary" 
          size="small" 
        />
      </Box>
      <Divider />
      <List>
        <ListItem button onClick={() => { navigate('/'); if (isMobile) onClose(); }}>
          <ListItemText primary="Road Data" />
        </ListItem>
        <ListItem button onClick={() => { navigate('/create-project'); if (isMobile) onClose(); }}>
          <ListItemText primary="Create Project" />
        </ListItem>
        <ListItem button onClick={() => { navigate('/3d'); if (isMobile) onClose(); }}>
          <ListItemText primary="3D View" />
        </ListItem>
      </List>
    </>
  );

  if (isMobile) {
    return (
      <Drawer
        variant="temporary"
        open={isOpen}
        onClose={onClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          '& .MuiDrawer-paper': { width: 240 },
        }}
      >
        {drawerContent}
      </Drawer>
    );
  }

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 180,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 180,
          boxSizing: 'border-box',
        },
      }}
    >
      {drawerContent}
    </Drawer>
  );
};

function App() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Box sx={{ display: 'flex' }}>
            {isMobile && (
              <AppBar position="fixed">
                <Toolbar>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2 }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Typography variant="h6" noWrap component="div">
                    Pave.ing
                  </Typography>
                </Toolbar>
              </AppBar>
            )}
            <Sidebar isMobile={isMobile} isOpen={mobileOpen} onClose={handleDrawerToggle} />
            <Box component="main" sx={{ flexGrow: 1, p: 3, mt: isMobile ? 8 : 0 }}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/create-project" element={<ProjectCreation />} />
                <Route path="/3d" element={<Home3D />} />
              </Routes>
            </Box>
          </Box>
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;