import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import MapboxGL from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import roadMarkers from "./NDGISHUB_Road_Mile_Markers_5712039635506747207.geojson";

const StyledMapContainer = styled(Box)(({ theme }) => ({
  height: "600px",
  marginBottom: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
  position: "relative",
}));

const MapLockButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
  zIndex: 1,
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 120,
  marginBottom: theme.spacing(3),
}));

const stateCoordinates = {
  ND: [-100.4701, 47.5515, 7],
};

function Home() {
  const [selectedState, setSelectedState] = useState("ND");
  const [map, setMap] = useState(null);
  const [isMapLocked, setIsMapLocked] = useState(true);
  const [roads, setRoads] = useState([]);
  const [selectedRoad, setSelectedRoad] = useState("");
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const mapContainer = useRef(null);
  const rotationRef = useRef(null);

  useEffect(() => {
    MapboxGL.accessToken =
      "pk.eyJ1IjoiY29hY2h0cmFjazEyMyIsImEiOiJjbHp5c2dzMjUwa25oMmlwbmo1MzNwZDZwIn0.d1t0lIYw9hyVlonn6JKJKA";
    const newMap = new MapboxGL.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/outdoors-v12",
      center: [-100.4701, 47.5515], // Center on North Dakota
      zoom: 7,
      pitch: 60,
      bearing: 0,
      attributionControl: false,
      interactive: false, // Start with the map locked
    });

    setMap(newMap);

    newMap.on("load", () => {
      // Add 3D terrain
      newMap.addSource('mapbox-dem', {
        'type': 'raster-dem',
        'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
        'tileSize': 512,
        'maxzoom': 14
      });
      newMap.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });

      // Add 3D buildings
      const layers = newMap.getStyle().layers;
      const labelLayerId = layers.find(
        (layer) => layer.type === 'symbol' && layer.layout['text-field']
      ).id;
      
      newMap.addLayer(
        {
          'id': '3d-buildings',
          'source': 'composite',
          'source-layer': 'building',
          'filter': ['==', 'extrude', 'true'],
          'type': 'fill-extrusion',
          'minzoom': 15,
          'paint': {
            'fill-extrusion-color': '#aaa',
            'fill-extrusion-height': [
              'interpolate',
              ['linear'],
              ['zoom'],
              15,
              0,
              15.05,
              ['get', 'height']
            ],
            'fill-extrusion-base': [
              'interpolate',
              ['linear'],
              ['zoom'],
              15,
              0,
              15.05,
              ['get', 'min_height']
            ],
            'fill-extrusion-opacity': 0.6
          }
        },
        labelLayerId
      );

      // Add this code to hide city labels until zoomed in
      newMap.setLayoutProperty("settlement-label", "visibility", "none");

      newMap.on("zoom", () => {
        if (newMap.getZoom() > 8) {
          newMap.setLayoutProperty("settlement-label", "visibility", "visible");
        } else {
          newMap.setLayoutProperty("settlement-label", "visibility", "none");
        }
      });

      fetch(roadMarkers)
        .then((response) => response.json())
        .then((data) => {
          // Process the GeoJSON data to create road lines
          const roadSegments = {};
          data.features.forEach((feature) => {
            const { HWY, DIRECTION, REF_PT_NUM } = feature.properties;
            const key = `${HWY}-${DIRECTION}`; // Use both highway and direction as the key
            if (!roadSegments[key]) {
              roadSegments[key] = [];
            }
            roadSegments[key].push({
              coordinates: feature.geometry.coordinates,
              refPoint: parseFloat(REF_PT_NUM),
            });
          });

          const roadList = Object.keys(roadSegments).sort();
          setRoads(roadList);
          setSelectedRoad(roadList[0]);

          const MAX_SEGMENT_LENGTH = 5; // Maximum length between points in miles
          const MILES_TO_DEGREES = 1 / 69; // Rough approximation of miles to degrees

          const roadLines = Object.entries(roadSegments).flatMap(
            ([key, points]) => {
              // Sort points by reference point
              points.sort((a, b) => a.refPoint - b.refPoint);

              // Create segments for continuous stretches
              const segments = [];
              let currentSegment = [points[0].coordinates];

              for (let i = 1; i < points.length; i++) {
                const prevPoint = points[i - 1];
                const currentPoint = points[i];

                // Calculate distance between points
                const dx =
                  currentPoint.coordinates[0] - prevPoint.coordinates[0];
                const dy =
                  currentPoint.coordinates[1] - prevPoint.coordinates[1];
                const distance =
                  Math.sqrt(dx * dx + dy * dy) / MILES_TO_DEGREES;

                // Check if the distance between points is reasonable and coordinates are not the same
                if (
                  distance <= MAX_SEGMENT_LENGTH &&
                  !(
                    currentPoint.coordinates[0] === prevPoint.coordinates[0] &&
                    currentPoint.coordinates[1] === prevPoint.coordinates[1]
                  )
                ) {
                  currentSegment.push(currentPoint.coordinates);
                } else {
                  // If the gap is too large or coordinates are the same, end the current segment and start a new one
                  if (currentSegment.length > 1) {
                    segments.push(currentSegment);
                  }
                  currentSegment = [currentPoint.coordinates];
                }
              }

              // Add the last segment if it has more than one point
              if (currentSegment.length > 1) {
                segments.push(currentSegment);
              }

              // Create a separate LineString feature for each segment
              return segments.map((segment) => ({
                type: "Feature",
                properties: { id: key },
                geometry: {
                  type: "LineString",
                  coordinates: segment,
                },
              }));
            }
          );

          // Add source for road markers (points)
          newMap.addSource("road-markers", {
            type: "geojson",
            data: data,
          });

          // Add source for road lines
          newMap.addSource("road-lines", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: roadLines,
            },
          });

          // Add layer for road lines (always visible)
          newMap.addLayer({
            id: "road-lines",
            type: "line",
            source: "road-lines",
            paint: {
              "line-color": "#0000FF",
              "line-width": 2,
            },
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
          });

          // Add layer for selected road (on top of other roads)
          newMap.addLayer({
            id: "selected-road",
            type: "line",
            source: "road-lines",
            paint: {
              "line-color": "#00FF00",
              "line-width": 3,
            },
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            filter: ["==", ["get", "id"], roadList[0]],
          });

          // Add layer for road markers (points)
          newMap.addLayer({
            id: "road-markers",
            type: "circle",
            source: "road-markers",
            paint: {
              "circle-radius": 3,
              "circle-color": "#808080",
            },
            minzoom: 8, // Only show markers when zoomed in enough
          });

          // Add click event to show popup with marker information
          newMap.on("click", "road-markers", (e) => {
            const coordinates = e.features[0].geometry.coordinates.slice();
            const properties = e.features[0].properties;

            // Create popup content with styled text
            const popupContent = `
              <div style="
                color: #ffffff;
                background-color: rgba(0, 0, 0, 0.8);
                padding: 16px;
                border-radius: 12px;
                box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
                backdrop-filter: blur(10px);
                font-family: 'Arial', sans-serif;
              ">
                <h3 style="
                  color: #00ffaa;
                  margin-bottom: 12px;
                  font-size: 18px;
                  font-weight: 600;
                  text-transform: uppercase;
                  letter-spacing: 1px;
                ">Mile Marker Info</h3>
                <div style="display: grid; grid-template-columns: auto 1fr; gap: 8px; align-items: center;">
                  <span style="color: #00ffaa; font-weight: 600;">Highway:</span>
                  <span>${properties.HWY}</span>
                  <span style="color: #00ffaa; font-weight: 600;">Reference Point:</span>
                  <span>${parseFloat(properties.REF_PT_NUM).toFixed(1)}</span>
                  <span style="color: #00ffaa; font-weight: 600;">Direction:</span>
                  <span>${properties.DIRECTION}</span>
                </div>
              </div>
            `;

            new MapboxGL.Popup()
              .setLngLat(coordinates)
              .setHTML(popupContent)
              .addTo(newMap);
          });

          // Change cursor to pointer when hovering over a marker
          newMap.on("mouseenter", "road-markers", () => {
            newMap.getCanvas().style.cursor = "pointer";
          });

          // Change cursor back when not hovering over a marker
          newMap.on("mouseleave", "road-markers", () => {
            newMap.getCanvas().style.cursor = "";
          });
        })
        .catch((error) => console.error("Error loading GeoJSON:", error));

      // Add rotation animation
      const rotateCamera = () => {
        newMap.easeTo({
          bearing: newMap.getBearing() + 0.05,
          duration: 0,
          easing: (t) => t,
        });
        rotationRef.current = requestAnimationFrame(rotateCamera);
      };
      rotateCamera();

      // Function to handle first interaction
      const handleFirstInteraction = () => {
        if (isFirstLoad) {
          setIsFirstLoad(false);
          cancelAnimationFrame(rotationRef.current);
          newMap.easeTo({
            pitch: 45,
            bearing: 0,
            zoom: 7,
            duration: 1000,
          });
          newMap.off("mousedown", handleFirstInteraction);
          newMap.off("touchstart", handleFirstInteraction);
          newMap.off("wheel", handleFirstInteraction);
        }
      };

      // Add event listeners for first interaction
      newMap.on("mousedown", handleFirstInteraction);
      newMap.on("touchstart", handleFirstInteraction);
      newMap.on("wheel", handleFirstInteraction);
    });

    return () => {
      if (rotationRef.current) {
        cancelAnimationFrame(rotationRef.current);
      }
      newMap.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (map && map.isStyleLoaded()) {
      map.setFilter("selected-road", ["==", ["get", "id"], selectedRoad]);
    }
  }, [selectedRoad, map]);

  const handleStateChange = (event) => {
    const state = event.target.value;
    setSelectedState(state);
    if (map && stateCoordinates[state]) {
      map.flyTo({
        center: [stateCoordinates[state][0], stateCoordinates[state][1]],
        zoom: stateCoordinates[state][2],
        essential: true,
      });
    }
  };

  const handleRoadChange = (event) => {
    setSelectedRoad(event.target.value);
  };

  const toggleMapLock = () => {
    setIsMapLocked(!isMapLocked);
    if (map) {
      map.dragPan.enable();
      map.scrollZoom.enable();
      map.boxZoom.enable();
      map.dragRotate.enable();
      map.keyboard.enable();
      map.doubleClickZoom.enable();
      map.touchZoomRotate.enable();

      if (isMapLocked) {
        map.dragPan.disable();
        map.scrollZoom.disable();
        map.boxZoom.disable();
        map.dragRotate.disable();
        map.keyboard.disable();
        map.doubleClickZoom.disable();
        map.touchZoomRotate.disable();
      }
    }
  };

  return (
    <Box sx={{ bgcolor: "background.default", minHeight: "100vh" }}>
      <Container sx={{ mt: 4, mb: 8 }}>
        <StyledFormControl>
          <InputLabel id="state-select-label">Select State</InputLabel>
          <Select
            labelId="state-select-label"
            id="state-select"
            value={selectedState}
            label="Select State"
            onChange={handleStateChange}
            disabled
          >
            <MenuItem value="ND">ND</MenuItem>
          </Select>
        </StyledFormControl>

        <StyledFormControl sx={{ ml: 2 }}>
          <InputLabel id="road-select-label">Select Road</InputLabel>
          <Select
            labelId="road-select-label"
            id="road-select"
            value={selectedRoad}
            label="Select Road"
            onChange={handleRoadChange}
          >
            {roads.map((road) => (
              <MenuItem key={road} value={road}>
                {road}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>

        <StyledMapContainer>
          <MapLockButton onClick={toggleMapLock}>
            {isMapLocked ? <LockIcon /> : <LockOpenIcon />}
          </MapLockButton>
          <Box ref={mapContainer} sx={{ width: "100%", height: "100%" }} />
        </StyledMapContainer>
      </Container>
    </Box>
  );
}

export { Home };
