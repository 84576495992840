import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Container,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  TextField,
  Chip,
} from "@mui/material";
import MapboxGL from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import roadMarkers from "./NDGISHUB_Road_Mile_Markers_5712039635506747207.geojson";
import {
  Construction,
  CalendarToday,
  Scale,
  CloudUpload,
  WbSunny,
  DeviceThermostat,
} from "@mui/icons-material";

function ProjectCreation() {
  const [map, setMap] = useState(null);
  const [roads, setRoads] = useState([]);
  const [selectedRoad, setSelectedRoad] = useState("");
  const [clickedPoints, setClickedPoints] = useState([]);
  const mapContainer = useRef(null);
  const [allRoadPoints, setAllRoadPoints] = useState([]);
  const allRoadPointsRef = useRef([]);

  // New state variables for form inputs
  const [projectName, setProjectName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [productionUnit, setProductionUnit] = useState("");
  const [weatherCollectionMethod, setWeatherCollectionMethod] = useState("");
  const [hyperlocalDevice, setHyperlocalDevice] = useState("");
  const [files, setFiles] = useState([]);

  useEffect(() => {
    MapboxGL.accessToken =
      "pk.eyJ1IjoiY29hY2h0cmFjazEyMyIsImEiOiJjbHp5c2dzMjUwa25oMmlwbmo1MzNwZDZwIn0.d1t0lIYw9hyVlonn6JKJKA";
    const newMap = new MapboxGL.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/dark-v10",
      center: [-100.4701, 47.5515], // Center on North Dakota
      zoom: 5,
    });

    setMap(newMap);

    newMap.on("load", () => {
      console.log("Map loaded");
      fetch(roadMarkers)
        .then((response) => response.json())
        .then((data) => {
          console.log("GeoJSON data loaded", data.features.length);
          const uniqueRoads = [
            ...new Set(
              data.features.map(
                (feature) =>
                  `${feature.properties.HWY}-${feature.properties.DIRECTION}`
              )
            ),
          ];
          setRoads(uniqueRoads.sort());
          setSelectedRoad(uniqueRoads[0]);
          setAllRoadPoints(data.features);
          allRoadPointsRef.current = data.features;

          newMap.addSource("road-markers", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: data.features,
            },
          });

          newMap.addLayer({
            id: "road-markers",
            type: "circle",
            source: "road-markers",
            paint: {
              "circle-radius": 6,
              "circle-color": "#ffffff",
              "circle-stroke-width": 1,
              "circle-stroke-color": "#000000",
            },
            filter: ["==", ["concat", ["get", "HWY"], "-", ["get", "DIRECTION"]], uniqueRoads[0]],
          });

          newMap.addLayer({
            id: "road-markers-labels",
            type: "symbol",
            source: "road-markers",
            layout: {
              "text-field": ["get", "REF_PT_NUM"],
              "text-size": 12,
              "text-offset": [0, -1],
            },
            paint: {
              "text-color": "#ffffff",
              "text-halo-color": "#000000",
              "text-halo-width": 1,
            },
            filter: ["==", ["concat", ["get", "HWY"], "-", ["get", "DIRECTION"]], uniqueRoads[0]],
          });

          console.log("Layers added");

          newMap.on("click", "road-markers", (e) => {
            console.log("Marker clicked", e.features[0].properties);
            const coordinates = e.features[0].geometry.coordinates.slice();
            const properties = e.features[0].properties;
            const newPoint = { coordinates, properties };
            
            setClickedPoints((prevPoints) => {
              let updatedPoints = [...prevPoints, newPoint];
              console.log("All clicked points:", updatedPoints);
              
              updatedPoints.sort(
                (a, b) =>
                  parseFloat(a.properties.REF_PT_NUM) -
                  parseFloat(b.properties.REF_PT_NUM)
              );

              if (updatedPoints.length >= 2) {
                const startPoint = updatedPoints[0];
                const endPoint = updatedPoints[updatedPoints.length - 1];
                console.log("Start point:", startPoint);
                console.log("End point:", endPoint);

                const connectedPoints = getPointsBetween(startPoint, endPoint);
                console.log("Connected points:", connectedPoints);

                drawRouteLine(newMap, connectedPoints);
                updateMarkerColors(newMap, connectedPoints);
              } else {
                console.log("Not enough points to draw a line yet");
                updateMarkerColors(newMap, updatedPoints);
              }
              
              return updatedPoints;
            });
          });

          newMap.on("mouseenter", "road-markers", () => {
            newMap.getCanvas().style.cursor = "pointer";
          });

          newMap.on("mouseleave", "road-markers", () => {
            newMap.getCanvas().style.cursor = "";
          });

          console.log("Click event listener added");
        })
        .catch((error) => console.error("Error loading GeoJSON:", error));
    });

    return () => {
      newMap.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPointsBetween = (start, end) => {
    console.log("Getting points between:", start, end);
    if (!start || !end) {
      console.error("Invalid start or end point");
      return [];
    }

    const currentAllRoadPoints = allRoadPointsRef.current;
    console.log("allRoadPoints length:", currentAllRoadPoints.length);
    if (currentAllRoadPoints.length === 0) {
      console.error("allRoadPoints is empty");
      return [];
    }

    const startRP = parseFloat(start.properties.REF_PT_NUM);
    const endRP = parseFloat(end.properties.REF_PT_NUM);
    const [minRP, maxRP] = [Math.min(startRP, endRP), Math.max(startRP, endRP)];

    console.log("Filtering points between", minRP, "and", maxRP);
    console.log("Highway:", start.properties.HWY, "Direction:", start.properties.DIRECTION);

    const filteredPoints = currentAllRoadPoints
      .filter(point => {
        const pointRP = parseFloat(point.properties.REF_PT_NUM);
        const isInRange = pointRP >= minRP && pointRP <= maxRP;
        const isMatchingRoad = point.properties.HWY === start.properties.HWY &&
                               point.properties.DIRECTION === start.properties.DIRECTION;
        return isInRange && isMatchingRoad;
      })
      .map((point) => ({
        coordinates: point.geometry.coordinates,
        properties: point.properties,
      }))
      .sort(
        (a, b) =>
          parseFloat(a.properties.REF_PT_NUM) -
          parseFloat(b.properties.REF_PT_NUM)
      );

    console.log("Filtered points:", filteredPoints);
    return filteredPoints;
  };

  const updateMarkerColors = (map, selectedPoints) => {
    const selectedRefPtNums = selectedPoints.map(
      (point) => point.properties.REF_PT_NUM
    );
    map.setPaintProperty("road-markers", "circle-color", [
      "case",
      ["in", ["get", "REF_PT_NUM"], ["literal", selectedRefPtNums]],
      "#00ff00",
      "#ffffff",
    ]);
  };

  useEffect(() => {
    if (map && map.isStyleLoaded() && map.getLayer('road-markers')) {
      map.setFilter("road-markers", [
        "==",
        ["concat", ["get", "HWY"], "-", ["get", "DIRECTION"]],
        selectedRoad,
      ]);
      map.setFilter("road-markers-labels", [
        "==",
        ["concat", ["get", "HWY"], "-", ["get", "DIRECTION"]],
        selectedRoad,
      ]);
    }
  }, [selectedRoad, map]);

  useEffect(() => {
    if (map && clickedPoints.length > 1) {
      const newPoints = getPointsBetween(clickedPoints[0], clickedPoints[clickedPoints.length - 1]);
      drawRouteLine(map, newPoints);
    }
  }, [map, clickedPoints]);

  const drawRouteLine = (map, points) => {
    console.log("Drawing route line with points:", points);
    const routeCoordinates = points.map((point) => point.coordinates);

    if (map.getSource("route")) {
      console.log("Updating existing route");
      // Update existing source
      map.getSource("route").setData({
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: routeCoordinates,
        },
      });
    } else {
      console.log("Adding new route source and layer");
      // Add new source and layer if they don't exist
      map.addSource("route", {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: routeCoordinates,
          },
        },
      });

      map.addLayer({
        id: "route",
        type: "line",
        source: "route",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#ff0000",
          "line-width": 3,
        },
      });
    }
  };

  const removeRouteLine = (map) => {
    if (map.getLayer("route")) {
      map.removeLayer("route");
    }
    if (map.getSource("route")) {
      map.removeSource("route");
    }
  };

  const handleRoadChange = (event) => {
    const newSelectedRoad = event.target.value;
    setSelectedRoad(newSelectedRoad);
    setClickedPoints([]);
    if (map) {
      removeRouteLine(map);
      updateMarkerColors(map, []);
      
      // Update the filter for the road-markers layer
      map.setFilter("road-markers", [
        "==",
        ["concat", ["get", "HWY"], "-", ["get", "DIRECTION"]],
        newSelectedRoad,
      ]);

      // Update the filter for the road-markers-labels layer
      map.setFilter("road-markers-labels", [
        "==",
        ["concat", ["get", "HWY"], "-", ["get", "DIRECTION"]],
        newSelectedRoad,
      ]);

      // Fit the map to the bounds of the selected road
      const selectedRoadFeatures = allRoadPoints.filter(
        feature => `${feature.properties.HWY}-${feature.properties.DIRECTION}` === newSelectedRoad
      );
      if (selectedRoadFeatures.length > 0) {
        const bounds = new MapboxGL.LngLatBounds();
        selectedRoadFeatures.forEach(feature => {
          bounds.extend(feature.geometry.coordinates);
        });
        map.fitBounds(bounds, { padding: 50 });
      }
    }
  };

  const handleResetSelection = () => {
    setClickedPoints([]);
    if (map) {
      removeRouteLine(map);
      updateMarkerColors(map, []);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission here
    console.log({
      projectName,
      startDate,
      endDate,
      productionUnit,
      selectedRoad,
      clickedPoints,
    });
  };

  const handleFilesDrop = (event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
  };

  return (
    <Box sx={{ bgcolor: "background.default", minHeight: "100vh" }}>
      <Container sx={{ mt: 4, mb: 8 }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
          {/* Map controls */}
          <Box sx={{ display: "flex", gap: 1 }}>
            <FormControl sx={{ minWidth: 120 }}>
              <Select
                value={selectedRoad}
                onChange={handleRoadChange}
                displayEmpty
                inputProps={{ "aria-label": "Select Road" }}
              >
                <MenuItem value="" disabled>
                  Select Road
                </MenuItem>
                {roads.map((road) => (
                  <MenuItem key={road} value={road}>
                    {road}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button variant="contained" onClick={handleResetSelection}>
              Reset
            </Button>
          </Box>

          {/* Map */}
          <Box sx={{ height: "400px", mb: 4, borderRadius: 1, overflow: "hidden", position: "relative" }}>
            <Box ref={mapContainer} sx={{ width: "100%", height: "100%" }} />
          </Box>

          {/* Form fields */}
          <Box component="form" onSubmit={handleSubmit}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
              <Construction sx={{ mr: 2, color: "primary.main" }} />
              <TextField
                label="Project Name"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                fullWidth
                required
              />
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
              <CalendarToday sx={{ mr: 2, color: "primary.main" }} />
              <TextField
                label="Start Date"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
                required
              />
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
              <CalendarToday sx={{ mr: 2, color: "primary.main" }} />
              <TextField
                label="End Date"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
                required
              />
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
              <Scale sx={{ mr: 2, color: "primary.main" }} />
              <FormControl fullWidth>
                <InputLabel id="production-unit-select-label">Production Unit</InputLabel>
                <Select
                  labelId="production-unit-select-label"
                  id="production-unit-select"
                  value={productionUnit}
                  onChange={(e) => setProductionUnit(e.target.value)}
                  required
                >
                  <MenuItem value="tons">Tons</MenuItem>
                  <MenuItem value="cubic_yards">Cubic Yards</MenuItem>
                  <MenuItem value="square_yards">Square Yards</MenuItem>
                  <MenuItem value="linear_feet">Linear Feet</MenuItem>
                  <MenuItem value="lane_miles">Lane Miles</MenuItem>
                  <MenuItem value="metric_tons">Metric Tons</MenuItem>
                </Select>
              </FormControl>
            </Box>

            {/* Weather Collection Method */}
            <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
              <WbSunny sx={{ mr: 2, color: "primary.main" }} />
              <FormControl fullWidth>
                <InputLabel id="weather-method-select-label">Select Weather Collection Method</InputLabel>
                <Select
                  labelId="weather-method-select-label"
                  id="weather-method-select"
                  value={weatherCollectionMethod}
                  onChange={(e) => setWeatherCollectionMethod(e.target.value)}
                  required
                >
                  <MenuItem value="hyperlocal">Hyperlocal</MenuItem>
                  <MenuItem value="system-weather">
                    System Weather
                    <Chip
                      label="PRO"
                      size="small"
                      color="primary"
                      sx={{ ml: 1, height: 20, fontSize: '0.7rem' }}
                    />
                  </MenuItem>
                  <MenuItem value="weather-station">Both</MenuItem>
                </Select>
              </FormControl>
            </Box>

            {/* Hyperlocal Device Selection */}
            {weatherCollectionMethod === "hyperlocal" && (
              <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
                <DeviceThermostat sx={{ mr: 2, color: "primary.main" }} />
                <FormControl fullWidth>
                  <InputLabel id="hyperlocal-device-select-label">Select Hyperlocal Device</InputLabel>
                  <Select
                    labelId="hyperlocal-device-select-label"
                    id="hyperlocal-device-select"
                    value={hyperlocalDevice}
                    onChange={(e) => setHyperlocalDevice(e.target.value)}
                    required
                  >
                    <MenuItem value="device1">WeatherPro X1000</MenuItem>
                    <MenuItem value="device2">ClimateTracker 2.0</MenuItem>
                    <MenuItem value="device3">AtmoSense Elite</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            )}

            <Box
              sx={{
                border: "2px dashed",
                borderColor: "primary.main",
                borderRadius: 1,
                p: 3,
                textAlign: "center",
                cursor: "pointer",
                mb: 3
              }}
              onDrop={handleFilesDrop}
              onDragOver={(e) => e.preventDefault()}
            >
              <CloudUpload sx={{ fontSize: 48, color: "primary.main", mb: 2 }} />
              <Typography>
                Drag and drop files here or click to select
              </Typography>
              {files.length > 0 && (
                <Typography variant="body2" sx={{ mt: 2 }}>
                  {files.length} file(s) selected
                </Typography>
              )}
            </Box>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
            >
              Submit Project
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export { ProjectCreation };
