import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#ffcc00', // Road marking yellow
      light: '#ffdb4d',
      dark: '#cca300',
    },
    secondary: {
      main: '#3366cc', // Blue for information signs
      light: '#5c85d6',
      dark: '#254e99',
    },
    background: {
      default: '#121212', // Dark background
      paper: '#1e1e1e',
    },
    text: {
      primary: '#ffffff',
      secondary: '#b0b0b0',
    },
    error: {
      main: '#ff6b6b', // Softer red for dark mode
    },
    warning: {
      main: '#ffa94d', // Softer orange for dark mode
    },
    info: {
      main: '#4dabf5', // Lighter blue for dark mode
    },
    success: {
      main: '#69db7c', // Softer green for dark mode
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
    },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
  },
});

export default theme;